import { QueryClient, QueryClientProvider } from "react-query";
import MapboxMap from "../components/MapboxMap";
import logo from "../assets/logo-mongo-trp.png";

const Map = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <div className="container flex flex-col">
        <div className="fixed left-0 top-0 w-full bg-black text-white flex flex-col gap-5 sm:gap-0 sm:flex-row justify-between px-14 md:px-32 items-center py-1 md:py-2 z-50">
          <a href="https://mongopatrimoine.com/">
            <img className="w-20 md:w-40" src={logo} alt="Logo de Mongo Patrimoine" />
          </a>
          
          <a className="py-1 md:py-2 px-3 md:px-6 rounded-full font-semibold md:font-bold md:text-base sm:text-sm text-xs bg-sky-400 hover:bg-sky-500" href="https://mongopatrimoine.com/en">Return to the site</a>
        </div>
        <div className="z-0 pt-7 md:pt-14">
          <MapboxMap />
        </div>
        <footer className="bg-black text-white text-xs sm:text-sm md:text-base text-center ">
          <p>&copy; {new Date().getFullYear()} <a className="hover:underline" href="https://mongopatrimoine.com/en"> <span className="uppercase">Mongo Patrimoine</span>. </a>All rights reserved.</p>
        </footer>
      </div>
    </QueryClientProvider>
  );
};

export default Map;
