import React, { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { cities } from "./CityApi";

const MapboxMap = () => {
  const mapContainerRef = useRef(null);

  const fetchCityCoordinates = async (city) => {
    let coordinates = null;
  
    // Manually set coordinates for Bukavu and Kalemie
    if (city.name === 'Bukavu') {
      coordinates = [ 28.8428, -2.4907];
    } else if (city.name === 'Kalemie') {
      coordinates = [29.1962, -5.933];
    } else {
      // Fetch coordinates using Mapbox API for other cities
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${city.name}, ${city.country}.json?access_token=pk.eyJ1IjoiYmFycm9uYWtuIiwiYSI6ImNsaHQ4eHhmbjA5NDgzbmw5YnN4YXBqbXcifQ.bs25Hlis-lhjQ5NxUt_6MA`
      );
      const data = await response.json();
      if (data.features && data.features.length > 0) {
        coordinates = data.features[0].center;
      }
    }
  
    return { city: city.name, coordinates };
  };
  

  const { data: cityCoordinates } = useQuery("cityCoordinates", async () => {
    const promises = cities.map(fetchCityCoordinates);
    return Promise.all(promises);
  });

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYmFycm9uYWtuIiwiYSI6ImNsaHQ4eHhmbjA5NDgzbmw5YnN4YXBqbXcifQ.bs25Hlis-lhjQ5NxUt_6MA";

    const isMobile = window.innerWidth < 768;
    const initialZoom = isMobile ? 0.5 : 2;
    const initialCenter = isMobile ? [0, 20] : [0, 0];

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/barronakn/clht9ey0q024i01pghj1n42kq",
      center: initialCenter,
      minZoom: 1,
      zoom: initialZoom,
    });

    map.on("load", () => {
      cityCoordinates.forEach(async (cityData) => {
        if (cityData && cityData.coordinates) {
          const [lng, lat] = cityData.coordinates;

          // Create a custom HTML element for the marker
          const markerElement = document.createElement("div");
          markerElement.style.backgroundColor = "red";
          markerElement.style.width = "10px"; // Adjust width to one-third of default
          markerElement.style.height = "10px"; // Adjust height to one-third of default
          markerElement.style.borderRadius = "50%";

          const marker = new mapboxgl.Marker({ element: markerElement })
            .setLngLat([lng, lat])
            .addTo(map);

          const city = cities.find((city) => city.name === cityData.city);

          const popupContent = `
            <p>City: ${city.name}</p>
            <p>Country: ${city.country}</p>
            <p>IDT: ${city.IDT}</p>
            <p>Population: Approx. ${city.population}</p>
            <p>Area: ${city.area}</p>
            <p>Timezones: ${city.timezones.join(", ")}</p>
            <p>Keys Strengths: ${city.key_strengths.join(", ")}</p>
            <p>Currency: ${city.currencies.join(", ")}</p>
            <p>Languages: ${city.languages.join(", ")}</p>
            <a target="_blank" style="color: blue; text-decoration: underline;" href=${city.link}>Learn more about investing in this country</a>
          `;

          const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent);

          marker.setPopup(popup);
        }
      });
    });

    return () => {
      map.remove();
    };
  }, [cityCoordinates]);

  return <div className="h-[82vh] md:h-[100vh]" ref={mapContainerRef}></div>;
};

export default MapboxMap;
