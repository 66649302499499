export const cities = [
  {
    name: "Dubai",
    country: "United Arab Emirates",
    population: "3.331 million",
    area: "4,114 km²",
    timezones: ["GMT+4"],
    currencies: ["United Arab Emirates Dirham (AED)"],
    languages: ["Arabic"],
    IDT: "Dubai International Airport (DXB)",
    key_strengths: ["Economic hub", "Financial Center", "Tourism"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/dubai/",
  },
  {
    name: "Abu Dhabi",
    country: "United Arab Emirates",
    population: "1.48 million",
    area: "67,340 km²",
    timezones: ["GMT+4"],
    currencies: ["United Arab Emirates Dirham (AED)"],
    languages: ["Arabic"],
    IDT: "Abu Dhabi International Airport (AUH)",
    key_strengths: ["Political Capital", "Oil Industry"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/abu-dhabi/",
  },
  {
    name: "Kinshasa",
    country: "Democratic Republic of the Congo",
    population: "14.342 million",
    area: "9,965 km²",
    timezones: ["GMT+1"],
    currencies: ["Congolese Franc (CDF)", "Dollars"],
    languages: ["French", "Lingala", "Kikongo", "Swahili"],
    IDT: "N'djili International Airport (FIH)",
    key_strengths: ["Economic Center", "Economic growth", "River Port"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/kinshasa/",
  },
  {
    name: "Goma",
    country: "Democratic Republic of the Congo",
    population: "1.056 million",
    area: "75.72 km²",
    timezones: ["GMT+1"],
    currencies: ["Congolese Franc (CDF)"],
    languages: ["French", "Lingala", "Kikongo", "Swahili"],
    IDT: "Goma Airport (GOM)",
    key_strengths: ["Strategic Region", "Natural Resources"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/goma/",
  },
  {
    name: "Lubumbashi",
    country: "Democratic Republic of the Congo",
    population: "2.015 million",
    area: "747.75 km²",
    timezones: ["GMT+2"],
    currencies: ["Congolese Franc (CDF)"],
    languages: ["French", "Lingala", "Kikongo", "Swahili"],
    IDT: "Lubumbashi International Airport (FBM)",
    key_strengths: ["Economic Center", "Mining Industry"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/lubumbashi/",
  },
  {
    name: "Brazzaville",
    country: "Republic of the Congo",
    population: "2.402 million",
    area: "342 km²",
    timezones: ["GMT+1"],
    currencies: ["Central African CFA Franc (XAF)"],
    languages: ["French", "Lingala", "Kikongo", "Swahili"],
    IDT: "Maya-Maya Airport (BZV)",
    key_strengths: ["Political Capital", "Culture"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/brazzaville/",
  },
  {
    name: "Pointe Noire",
    country: "Republic of the Congo",
    population: "1.119 million",
    area: "83.8 km²",
    timezones: ["GMT+1"],
    currencies: ["Central African CFA Franc (XAF)"],
    languages: ["French", "Lingala", "Kikongo", "Swahili"],
    IDT: "Antonio Agostinho Neto International Airport (PNR)",
    key_strengths: ["Commercial Port", "Oil Industry"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/pointe-noire/",
  },
  {
    name: "Accra",
    country: "Ghana",
    population: "2.557 million",
    area: "173.64 km²",
    timezones: ["GMT"],
    currencies: ["Ghanaian Cedi (GHS)"],
    languages: ["English"],
    IDT: "Kotoka International Airport (ACC)",
    key_strengths: ["Economic Center", "Tourism"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/accra/",
  },
  {
    name: "Abidjan",
    country: "Ivory Coast",
    population: "4.707 million",
    area: "2,119 km²",
    timezones: ["GMT"],
    currencies: ["West African CFA Franc (XOF)"],
    languages: ["French"],
    IDT: "Félix Houphouët-Boigny International Airport (ABJ)",
    key_strengths: ["Economic hub", "Commercial Port"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/abidjan/",
  },
  {
    name: "Dakar",
    country: "Senegal",
    population: "3.775 million",
    area: "83.89 km²",
    timezones: ["GMT"],
    currencies: ["West African CFA Franc (XOF)"],
    languages: ["French"],
    IDT: "Blaise Diagne International Airport (DSS)",
    key_strengths: ["Economic Center", "Seaport"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/dakar/",
  },
  {
    name: "Libreville",
    country: "Gabon",
    population: "703,904",
    area: "65,608 km²",
    timezones: ["GMT+1"],
    currencies: ["Central African CFA Franc (XAF)"],
    languages: ["French"],
    IDT: "Léon Mba International Airport (LBV)",
    key_strengths: ["Political Capital", "Seaport"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/libreville/",
  },
  {
    name: "Cape Town",
    country: "South Africa",
    population: "3.777 million",
    area: "2,455 km²",
    timezones: ["GMT+2"],
    currencies: ["South African Rand (ZAR)"],
    languages: ["Afrikaans", "English", "Xhosa", "Zulu"],
    IDT: "Cape Town International Airport (CPT)",
    key_strengths: ["Tourism", "Wine Industry"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/cape-town/",
  },
  {
    name: "Johannesburg",
    country: "South Africa",
    population: "5.635 million",
    area: "1,644 km²",
    timezones: ["GMT+2"],
    currencies: ["South African Rand (ZAR)"],
    languages: ["English", "Afrikaans", "Zulu", "Sesotho"],
    IDT: "OR Tambo International Airport (JNB)",
    key_strengths: ["Economic Center", "Natural Ressources", "Mining Industry"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/johannesburg/",
  },
  {
    name: "Perth",
    country: "Australia",
    population: "2.041 million",
    area: "5,386 km²",
    timezones: ["GMT+8"],
    currencies: ["Australian Dollar (AUD)"],
    languages: ["English"],
    IDT: "Perth Airport (PER)",
    key_strengths: [
      "Economic Center",
      "Mining industry",
      "Natural ressources",
      "Seaport",
    ],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/perth/",
  },
  {
    name: "Melbourne",
    country: "Australia",
    population: "5.078 million",
    area: "9,993.5 km²",
    timezones: ["GMT+10"],
    currencies: ["Australian Dollar (AUD)"],
    languages: ["English"],
    IDT: "Melbourne Airport (MEL)",
    key_strengths: ["Cultural Center", "Education"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/melbourne/",
  },
  {
    name: "Sydney",
    country: "Australia",
    population: "5.312 million",
    area: "12,367.7 km²",
    timezones: ["GMT+10"],
    currencies: ["Australian Dollar (AUD)"],
    languages: ["English"],
    IDT: "Sydney Airport (SYD)",
    key_strengths: ["Economic Center", "Tourism"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/sydney/",
  },
  {
    name: "Darwin",
    country: "Australia",
    population: "148,564",
    area: "112.01 km²",
    timezones: ["GMT+9:30"],
    currencies: ["Australian Dollar (AUD)"],
    languages: ["English"],
    IDT: "Darwin International Airport (DRW)",
    key_strengths: ["Administrative Center", "Natural Resources"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/darwin/",
  },
  {
    name: "Bali",
    country: "Indonesia",
    population: "4.317 million",
    area: "5,780 km²",
    timezones: ["GMT+8"],
    currencies: ["Indonesian Rupiah (IDR)"],
    languages: ["Balinese", "Indonesian"],
    IDT: "Ngurah Rai International Airport (DPS)",
    key_strengths: ["Tourism", "Balinese Culture"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/bali/",
  },
  {
    name: "Luanda",
    country: "Angola",
    population: "8.861 million",
    area: "2,257 km²",
    timezones: ["GMT+1"],
    currencies: ["Angolan Kwanza (AOA)"],
    languages: ["Portuguese"],
    IDT: "4 de Fevereiro International Airport (LAD)",
    key_strengths: ["Economic Center", "Seaport"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/luanda/",
  },
  {
    name: "Casablanca",
    country: "Morocco",
    population: "3.359 million",
    area: "220 km²",
    timezones: ["GMT"],
    currencies: ["Moroccan Dirham (MAD)"],
    languages: ["Arabic", "Berber", "French"],
    IDT: "Mohammed V Airport (CMN)",
    key_strengths: ["Economic and financial Center", "Port Industry"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/casablanca/",
  },
  {
    name: "Marrakech",
    country: "Morocco",
    population: "928,850",
    area: "230 km²",
    timezones: ["GMT"],
    currencies: ["Moroccan Dirham (MAD)"],
    languages: ["Arabic", "Berber", "French"],
    IDT: "Marrakech-Menara Airport (RAK)",
    key_strengths: ["Tourism", "Moroccan Culture"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/marrakech/",
  },
  {
    name: "Lagos",
    country: "Nigeria",
    population: "16.536 million",
    area: "1,171 km²",
    timezones: ["GMT+1"],
    currencies: ["Nigerian Naira (NGN)"],
    languages: ["English"],
    IDT: "Murtala Muhammed International Airport (LOS)",
    key_strengths: ["Economic Hub", "Strong economic growth", "Seaport"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/lagos/",
  },
  {
    name: "Abuja",
    country: "Nigeria",
    population: "1.915 million",
    area: "713 km²",
    timezones: ["GMT+1"],
    currencies: ["Nigerian Naira (NGN)"],
    languages: ["English"],
    IDT: "Nnamdi Azikiwe International Airport (ABV)",
    key_strengths: ["Political Capital", "Administrative Center"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/abuja/",
  },
  {
    name: "London",
    country: "England",
    population: "8.982 million",
    area: "1,572 km²",
    timezones: ["GMT"],
    currencies: ["British Pound (GBP)"],
    languages: ["English"],
    IDT: "Heathrow Airport (LHR)",
    key_strengths: ["Financial Center", "Cultural"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/london/",
  },
  {
    name: "New York City",
    country: "United States of America",
    population: "8.175 million",
    area: "789 km²",
    timezones: ["GMT-4"],
    currencies: ["US Dollar (USD)"],
    languages: ["English"],
    IDT: "John F. Kennedy International Airport (JFK)",
    key_strengths: ["Economic Hub", "Financial Center", "Cultural"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/nyc/",
  },
  {
    name: "New Jersey",
    country: "United States of America",
    population: "8.882 million",
    area: "22,591 km²",
    timezones: ["GMT-4"],
    currencies: ["US Dollar (USD)"],
    languages: ["English"],
    IDT: "Newark Liberty International Airport (EWR)",
    key_strengths: ["Economic Center", "Cultural"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/new-jersey/",
  },
  {
    name: "Los Angeles",
    country: "United States of America",
    population: "3.979 million",
    area: "1,302 km²",
    timezones: ["GMT-7"],
    currencies: ["US Dollar (USD)"],
    languages: ["English"],
    IDT: "Los Angeles International Airport (LAX)",
    key_strengths: ["Entertainment", "Film Industry"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/los-angeles/",
  },
  {
    name: "San Diego",
    country: "United States of America",
    population: "1.423 million",
    area: "964.5 km²",
    timezones: ["GMT-7"],
    currencies: ["US Dollar (USD)"],
    languages: ["English"],
    IDT: "San Diego International Airport (SAN)",
    key_strengths: ["Scientific Research", "Coastal Tourism"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/san-diego/",
  },
  {
    name: "Washington D.C.",
    country: "United States of America",
    population: "705,749",
    area: "177 km²",
    timezones: ["GMT-4"],
    currencies: ["US Dollar (USD)"],
    languages: ["English"],
    IDT: "Washington Dulles International Airport (IAD)",
    Continent: "North America",
    key_strengths: [
      "Economic and Political Capital",
      "Government Institutions",
    ],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/washington/",
  },
  {
    name: "Marseille",
    country: "France",
    population: "861,635",
    area: "240.62 km²",
    timezones: ["GMT+1"],
    currencies: ["Euro (EUR)"],
    languages: ["French"],
    IDT: "Marseille Provence Airport (MRS)",
    Continent: "Europe",
    key_strengths: ["Seaport", "Cultural"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/marseille/",
  },
  {
    name: "Lisboa",
    country: "Portugal",
    population: "504,718",
    area: "100.05 km²",
    timezones: ["GMT"],
    currencies: ["Euro (EUR)"],
    languages: ["Portuguese"],
    IDT: "Humberto Delgado Airport (LIS)",
    Continent: "Europe",
    key_strengths: ["Economic Center", "Tourism"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/lisboa/",
  },
  {
    name: "Faro",
    country: "Portugal",
    population: "60,995",
    area: "202.57 km²",
    timezones: ["GMT"],
    currencies: ["Euro (EUR)"],
    languages: ["Portuguese"],
    IDT: "Faro Airport (FAO)",
    Continent: "Europe",
    key_strengths: ["Tourism", "Seaport"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/faro/",
  },
  {
    name: "Rio de Janeiro",
    country: "Brazil",
    population: "6.748 million",
    area: "1,221 km²",
    timezones: ["GMT-3"],
    currencies: ["Brazilian Real (BRL)"],
    languages: ["Portuguese"],
    IDT: "Galeão International Airport (GIG)",
    Continent: "South America",
    key_strengths: ["Tourism", "Cultural"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/rio-de-janeiro/",
  },
  {
    name: "São Paulo",
    country: "Brazil",
    population: "12.325 million",
    area: "1,521 km²",
    timezones: ["GMT-3"],
    currencies: ["Brazilian Real (BRL)"],
    languages: ["Portuguese"],
    IDT: "Guarulhos International Airport (GRU)",
    Continent: "South America",
    key_strengths: ["Economic Center", "Cultural"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/sao-paulo/",
  },
  {
    name: "Salvador",
    country: "Brazil",
    population: "2.886 million",
    area: "693.8 km²",
    timezones: ["GMT-3"],
    currencies: ["Brazilian Real (BRL)"],
    languages: ["Portuguese"],
    IDT: "Deputado Luís Eduardo Magalhães International Airport (SSA)",
    Continent: "South America",
    key_strengths: ["Cultural", "Tourism"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/salvado/",
  },
  {
    name: "Miami",
    country: "United States of America",
    population: "467,963",
    area: "143.1 km²",
    timezones: ["GMT-4"],
    currencies: ["US Dollar (USD)"],
    languages: ["English"],
    IDT: "Miami International Airport (MIA)",
    Continent: "North America",
    key_strengths: ["Economic Center", "Tourism", "Cultural"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/miami/",
  },
  {
    name: "Dallas",
    country: "United States of America",
    population: "1.343 million",
    area: "997.1 km²",
    timezones: ["GMT-5"],
    currencies: ["US Dollar (USD)"],
    languages: ["English"],
    IDT: "Dallas/Fort Worth International Airport (DFW)",
    key_strengths: ["Economic Center", "Technology", "Oil and Gas industry"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/dallas/",
  },
  {
    name: "Lyon",
    country: "France",
    population: "513,275",
    area: "47.87 km²",
    timezones: ["GMT+2"],
    currencies: ["Euro (EUR)"],
    languages: ["French"],
    IDT: "Lyon-Saint-Exupéry Airport (LYS)",
    key_strengths: ["Heritage", "Gastronomy"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/lyon-annecy-annemasse/",
  },
  {
    name: "Annecy",
    country: "France",
    population: "126,924",
    area: "13.65 km²",
    timezones: ["GMT+2"],
    currencies: ["Euro (EUR)"],
    languages: ["French"],
    IDT: "Annecy Mont-Blanc Airport (NCY)",
    key_strengths: ["Lake Annecy", "Nature"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/lyon-annecy-annemasse/",
  },
  {
    name: "Annemasse",
    country: "France",
    population: "36,874",
    area: "4.72 km²",
    timezones: ["GMT+2"],
    currencies: ["Euro (EUR)"],
    languages: ["French"],
    IDT: "Geneva Airport (GVA)",
    key_strengths: ["Proximity to Geneva", "Quality of Life"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/lyon-annecy-annemasse/",
  },
  {
    name: "Cannes",
    country: "France",
    population: "74,644",
    area: "19.62 km²",
    timezones: ["GMT+2"],
    currencies: ["Euro (EUR)"],
    languages: ["French"],
    IDT: "Cannes Mandelieu Airport (CEQ)",
    key_strengths: ["Cannes Film Festival", "Heritage", "Beaches (French Riviera)"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/cannes-antibes-nice-menton-french-riviera/",
  },
  {
    name: "Antibes",
    country: "France",
    population: "75,568",
    area: "26.48 km²",
    timezones: ["GMT+2"],
    currencies: ["Euro (EUR)"],
    languages: ["French"],
    IDT: "Nice Côte d'Azur Airport (NCE)",
    key_strengths: ["Heritage", "Beaches (French Riviera)"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/cannes-antibes-nice-menton-french-riviera/",
  },
  {
    name: "Nice",
    country: "France",
    population: "342,522",
    area: "71.92 km²",
    timezones: ["GMT+2"],
    currencies: ["Euro (EUR)"],
    languages: ["French"],
    IDT: "Nice Côte d'Azur Airport (NCE)",
    key_strengths: ["Culture", "Beaches (French Riviera)"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/cannes-antibes-nice-menton-french-riviera/",
  },
  {
    name: "Menton",
    country: "France",
    population: "28,857",
    area: "13.04 km²",
    timezones: ["GMT+2"],
    currencies: ["Euro (EUR)"],
    languages: ["French"],
    IDT: "Nice Côte d'Azur Airport (NCE)",
    key_strengths: ["Proximity to Monaco", "Heritage", "Beaches (French Riviera)"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/cannes-antibes-nice-menton-french-riviera/",
  },
  {
    name: "Geneva",
    country: "Switzerland",
    population: "201,818",
    area: "15.93 km²",
    timezones: ["GMT+2"],
    currencies: ["Swiss Franc (CHF)"],
    languages: ["French", "German", "Italian", "Romansh"],
    IDT: "Geneva Airport (GVA)",
    key_strengths: ["Seat of Many International Organizations", "Lake Geneva"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/geneva/",
  },
  {
    name: "Kigali",
    country: "Rwanda",
    population: "1.132 million",
    area: "730 km²",
    timezones: ["GMT+2"],
    currencies: ["Rwandan Franc (RWF)"],
    languages: ["Kinyarwanda", "English", "French", "Swahili"],
    IDT: "Kigali International Airport (KGL)",
    key_strengths: [
      "Strong economic growth",
      "Clean City",
      "Sustainable Development",
    ],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/kigali/",
  },
  {
    name: "Nairobi",
    country: "Kenya",
    population: "4.397 million",
    area: "696 km²",
    timezones: ["GMT+3"],
    currencies: ["Kenyan Shilling (KES)"],
    languages: ["Swahili", "English"],
    IDT: "Jomo Kenyatta International Airport (NBO)",
    key_strengths: ["Regional Economic Hub", "National Parks"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/nairobi/",
  },
  {
    name: "Gaborone",
    country: "Botswana",
    population: "231,592",
    area: "172.8 km²",
    timezones: ["GMT+2"],
    currencies: ["Botswana Pula (BWP)"],
    languages: ["English", "Setswana"],
    IDT: "Sir Seretse Khama International Airport (GBE)",
    key_strengths: [
      "Economic Hub",
      "Tech Industry « African Silicon Valley »",
      "Political Stability",
    ],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/gaborone/",
  },
  {
    name: "Brussels",
    country: "Belgium",
    population: "1.208 million",
    area: "32.61 km²",
    timezones: ["GMT+1"],
    currencies: ["Euro (EUR)"],
    languages: ["Dutch", "French", "German"],
    IDT: "Brussels Airport (BRU)",
    key_strengths: ["Capital of the European Union", "Architecture"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/bruxels/",
  },
  {
    name: "Houston",
    country: "United States of America",
    population: "2.325 million",
    area: "1,651 km²",
    timezones: ["GMT-5"],
    currencies: ["US Dollar (USD)"],
    languages: ["English"],
    IDT: "George Bush Intercontinental Airport (IAH)",
    key_strengths: ["Economic Center", "Energy"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/houston/",
  },
  {
    name: "Austin",
    country: "United States of America",
    population: "978,908",
    area: "830.3 km²",
    timezones: ["GMT-5"],
    currencies: ["US Dollar (USD)"],
    languages: ["English"],
    IDT: "Austin-Bergstrom International Airport (AUS)",
    key_strengths: ["Technology", "Oil and Gas industry"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/austin/",
  },
  {
    name: "Oakland",
    country: "United States of America",
    population: "429,082",
    area: "145.2 km²",
    timezones: ["GMT-7"],
    currencies: ["US Dollar (USD)"],
    languages: ["English"],
    IDT: "Oakland International Airport (OAK)",
    key_strengths: ["Seaport", "Culture", "Proximity to San Francisco"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/oakland/",
  },
  {
    name: "San Francisco",
    country: "United States of America",
    population: "884,363",
    area: "600 km²",
    timezones: ["GMT-7"],
    currencies: ["US Dollar (USD)"],
    languages: ["English"],
    IDT: "San Francisco International Airport (SFO)",
    key_strengths: ["Tech Industry", "Silicon Valley", "Culture"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/san-francisco/",
  },
  {
    name: "Chicago",
    country: "United States of America",
    population: "2.693 million",
    area: "606.1 km²",
    timezones: ["GMT-5"],
    currencies: ["US Dollar (USD)"],
    languages: ["English"],
    IDT: "O'Hare International Airport (ORD)",
    key_strengths: ["Economic Center", "Culture"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/chicago/",
  },
  {
    name: "Istanbul",
    country: "Turkey",
    population: "15.52 million",
    area: "5,196 km²",
    timezones: ["GMT+3"],
    currencies: ["Turkish Lira (TRY)"],
    languages: ["Turkish"],
    IDT: "Istanbul Airport (IST)",
    key_strengths: [
      "Economic hub",
      "Bridge between Europe and Asia",
      "Historical Culture",
    ],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/istanbul/",
  },
  {
    name: "Shanghai",
    country: "China",
    population: "27.058 million",
    area: "6,340 km²",
    timezones: ["GMT+8"],
    currencies: ["Chinese Yuan (CNY)"],
    languages: ["Mandarin"],
    IDT: "Shanghai Pudong International Airport (PVG)",
    key_strengths: ["Economic hub", "Financial Center", "Cultural"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/shanghai/",
  },
  {
    name: "Guangzhou",
    country: "China",
    population: "14.904 million",
    area: "7,434 km²",
    timezones: ["GMT+8"],
    currencies: ["Chinese Yuan (CNY)"],
    languages: ["Mandarin", "Cantonese"],
    IDT: "Guangzhou Baiyun International Airport (CAN)",
    key_strengths: ["Economic Center", "Commercial hub", "Trade"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/guangzhou/",
  },
  {
    name: "Hong Kong",
    country: "Hong Kong",
    population: "7.392 million",
    area: "1,106 km²",
    timezones: ["GMT+8"],
    currencies: ["Hong Kong Dollar (HKD)"],
    languages: ["Chinese", "English"],
    IDT: "Hong Kong International Airport (HKG)",
    key_strengths: ["Financial Center", "Commercial Hub"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/hong-kong/",
  },
  {
    name: "Vancouver",
    country: "Canada",
    population: "675,218",
    area: "115 km²",
    timezones: ["GMT-7"],
    currencies: ["Canadian Dollar (CAD)"],
    languages: ["English", "French"],
    IDT: "Vancouver International Airport (YVR)",
    key_strengths: ["Economic Center", "Quality of Life", "Nature"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/vancouver/",
  },
  {
    name: "Toronto",
    country: "Canada",
    population: "2.93 million",
    area: "630.2 km²",
    timezones: ["GMT-4"],
    currencies: ["Canadian Dollar (CAD)"],
    languages: ["English", "French"],
    IDT: "Billy Bishop Toronto City Airport (YTZ)",
    key_strengths: ["Economic Hub", "Culture"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/toronto/",
  },
  {
    name: "Montreal",
    country: "Canada",
    population: "1.78 million",
    area: "431.5 km²",
    timezones: ["GMT-4"],
    currencies: ["Canadian Dollar (CAD)"],
    languages: ["French", "English"],
    IDT: "Montreal-Pierre Elliott Trudeau International Airport (YUL)",
    key_strengths: ["Economic Center", "Culture", "Technology"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/montreal/",
  },
  {
    name: "Paris",
    country: "France",
    population: "2.148 million",
    area: "105.4 km²",
    timezones: ["GMT+2"],
    currencies: ["Euro (EUR)"],
    languages: ["French"],
    IDT: "Charles de Gaulle Airport (CDG)",
    key_strengths: ["Culture", "Art", "Gastronomy"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/paris/",
  },
  {
    name: "Madrid",
    country: "Spain",
    population: "3,334,730",
    area: "604.3 km²",
    timezones: ["GMT+1"],
    currencies: ["Euro (EUR)"],
    languages: ["Spanish"],
    IDT: "Adolfo Suárez Madrid-Barajas Airport (MAD)",
    key_strengths: ["Capital City", "Cultural and Artistic Hub"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/madrid/",
  },
  {
    name: "Barcelona",
    country: "Spain",
    population: "5,575,477",
    area: "101.9 km²",
    timezones: ["GMT+1"],
    currencies: ["Euro (EUR)"],
    languages: ["Spanish", "Catalan"],
    IDT: "Barcelona–El Prat Josep Tarradellas Airport (BCN)",
    key_strengths: ["Cultural and Architectural Heritage", "Tourism"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/barcelone/",
  },
  {
    name: "Málaga",
    country: "Spain",
    population: "1,627,988",
    area: "395 km²",
    timezones: ["GMT+1"],
    currencies: ["Euro (EUR)"],
    languages: ["Spanish"],
    IDT: "Málaga-Costa del Sol Airport (AGP)",
    key_strengths: ["Tourism", "Cultural Attractions"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/malaga/",
  },
  {
    name: "Ibiza",
    country: "Spain",
    population: "152,883",
    area: "572.56 km²",
    timezones: ["GMT+1"],
    currencies: ["Euro (EUR)"],
    languages: ["Spanish", "Catalan"],
    IDT: "Ibiza Airport (IBZ)",
    key_strengths: ["Tourism", "Nightlife and Entertainment"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/ibiza-ile",
  },
  {
    name: "Kisangani",
    country: "Democratic Republic of the Congo",
    population: "1,500,000",
    area: "181 km²",
    timezones: ["GMT+2"],
    currencies: ["Congolese Franc (CDF)"],
    languages: ["French", "Lingala", "Kikongo", "Swahili"],
    IDT: "Bangoka International Airport (FKI)",
    key_strengths: ["Trade and Commercial Hub", "Historical Importance"],
    link: "https://mongopatrimoine.com/en/carte-des-investissements/kisangani",
},
{
  name: "Bukavu",
  country: "Democratic Republic of the Congo",
  population: "1,068,449",
  area: "52 km²",
  timezones: ["GMT+2"],
  currencies: ["Congolese Franc (CDF)"],
  languages: ["French", "Lingala", "Kikongo", "Swahili"],
  IDT: "Bukavu Kavumu International Airport (BKY)",
  key_strengths: ["Lake Port City", "Scenic Beauty"],
  link: "https://mongopatrimoine.com/en/carte-des-investissements/bukavu",
},
{
  name: "Kalemie",
  country: "Democratic Republic of the Congo",
  population: "516,000",
  area: "1,963 km²",
  timezones: ["GMT+2"],
  currencies: ["Congolese Franc (CDF)"],
  languages: ["French", "Swahili"],
  IDT: "Kalemie Airport (FMI)",
  key_strengths: ["Port City on Lake Tanganyika", "Fishing Industry"],
  link: "https://mongopatrimoine.com/en/carte-des-investissements/kalemie",
},
{
  name: "Assinie-Mafia",
  country: "Côte d'Ivoire",
  population: "21 941",
  area: "77,45 km²",
  timezones: ["GMT"],
  currencies: ["West African CFA franc (XOF)"],
  languages: ["French"],
  IDT: "Abidjan Airport (ABJ).",
  key_strengths: ["Tourist Destination", "Beach Resort"],
  link: "https://mongopatrimoine.com/en/carte-des-investissements/assinie",
},
{
  name: "Jacqueville",
  country: "Côte d'Ivoire",
  population: "49 694",
  area: "680 km²",
  timezones: ["GMT"],
  currencies: ["West African CFA franc (XOF)"],
  languages: ["French"],
  IDT: "Abidjan Airport (ABJ).",
  key_strengths: ["Tourism", "Coastal Town"],
  link: "https://mongopatrimoine.com/en/carte-des-investissements/jacqueville",
},
{
  name: "Kolwezi",
  country: "Democratic Republic of the Congo",
  population: "approx. 600,000",
  area: "213 km²",
  timezones: ["GMT+2"],
  currencies: ["Congolese Franc (CDF)"],
  languages: ["French", "Swahili"],
  IDT: "Kolwezi Airport",
  key_strengths: ["Mining Hub", "Natural Resources"],
  link: "https://mongopatrimoine.com/en/carte-des-investissements/kolwezi",
}
];
